import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { branchIdSelector, userDataSelector } from '@/selectors/selectors'
import { callGraphQlClient } from '@/functions/loaderGraphQl'
import { QAvailableCredits } from '@/graphql/query/user/credit/QAvailableCredits'

/**
 * Loads customer’s credit wallets to calculate available credits.
 */
export const useLoadAvailableCredits = () => {
    const dispatch = useDispatch()
    const userData = useSelector(userDataSelector)
    const selectedBranchId = useSelector(branchIdSelector)
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        // Zavolání GraphQL dotazu a nastavení stavových proměnných
        if (variables) loadAvailableCredits()
    }, [variables])

    const variables = React.useMemo(() => {
        if (!userData.id || !selectedBranchId) return undefined

        return {
            userId: userData.id,
            branchId: selectedBranchId
        }
    }, [userData, selectedBranchId])

    const loadAvailableCredits = async () => {
        if (variables) {
            try {
                callGraphQlClient(QAvailableCredits, variables, dispatch).then(res => {
                    setData(res)
                    setIsLoading(false)
                })
            } catch (err) {
                console.error(err)
                setIsLoading(false)
            }
        }
    }

    const result = React.useMemo(() => {
        if (!variables || !data)
            return {
                loading: !variables || isLoading,
                wallet: null
            }

        const hasWallets = data.customer.creditsWallets.length > 0

        // there should be only one wallet per customer & branch
        const wallet = hasWallets ? data.customer.creditsWallets[0] : null

        return {
            isLoading,
            wallet
        }
    }, [data, isLoading, variables])

    return result
}
